<template>
  <div>
    <dialog-edit2 :value="value" @input="$emit('input')">
      <template v-slot:title>
        <small v-if="id"> ({{ id }})</small>
        {{ id ? m.title : "Новый документ '" + m.title + "'" }}
      </template>
      <a-loader v-if="!loaded" />
      <a-form-model
        v-else
        ref="modelType"
        :key="type"
        v-model="data"
        :model="model"
        :errors="errors"
        :config="{ dense: true }"
        @validate="validate($event)"
      />
      <template v-slot:actions>
        <v-spacer></v-spacer>
        <a-btn-save @click="submit()"></a-btn-save>

        <v-btn @click="$emit('input')">Закрыть</v-btn>
      </template>
    </dialog-edit2>
  </div>
</template>

<script>
import { getForm, submitForm, removeEl, getAccess, genModel } from "@/components/mixings";

export default {
  components: {
    //  usersDialog: () => import("./usersDialog2"),
  },
  mixins: [getForm, submitForm, removeEl, getAccess, genModel],
  props: {
    value: Boolean,
    id: Number,
    api: String,
    m: Object,
    hasData: { type: Boolean, default: false },
    details: Array,
  },
  data() {
    return {
      removeDialogShow: false,
      idEdit: 0,
      suEditShow: false,
      modelDoc: null,
      modelReady: false,
      type: null,
      loaded: false,
    };
  },
  computed: {
    model: {
      get() {
        let type = this.id ? "editForm" : "createForm";
        let model = this.calcModel(type);
        //this.getModelList(this.m, type, true);

        model.forEach(el => {
          if (this.hasData)
            if ("parent_id,date_doc,code_doc".split(",").includes(el.name)) {
              el.readonly = true;
            }
          if (this.details) {
            //ограничить список складов, в зависимости от вида заказа
            if (el.name == "store_id") {
              if (this.details[0].store_id) el.dirFilter = { object_id: "%null" };
              if (this.details[0].object_id) el.dirFilter = { object_id: [this.details[0].object_id, null] };
            }
          }
        });
        return model;
      },
    },
  },
  created() {
    this.loaded = false;
    this.type = this.initType || null;
    this.updateData(this.id);
    if (!this.id) {
      this.loaded = true;
      ///  this.type = 1;
      //  this.data.status = 0;
    }
  },
  watch: {
    value1() {
      if (this.value) {
        this.updateData(this.id);
        if (!this.id) {
          this.type = 1;
          //  this.data.status = 0;
        }
      }
    },
  },
  methods: {
    afterRemove(data) {
      this.removeDialogShow = false;
      this.$emit("input");
    },
    afterSave(data, status) {
      if (status) this.$emit("input");
      let id = data.id;
      if (!this.id) this.$router.push({ name: this.m.routeName, params: { id } });
    },
    async beforeSave() {
      if (this.details) {
        this.data.data_table = this.details;
        this.data.parent_id = this.details[0].order_goods_id;
        this.data.parent_name = "AccountingDocOrderGoodModel";
      }
      return true;
    },
    afterFetchData(r) {
      this.loaded = true;
      // this.initType = null;

      //  this.calcModel(1);
    },
  },
};
</script>